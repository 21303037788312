

































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserKYC } from '@/interfaces';
import { readUserProfile } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchSendKYC } from '@/store/main/actions';


@Component
export default class UserProfileEdit extends Vue {
  public valid = true;
  // public U_source_choices = ['於臺灣交易所以新臺幣購買', '於國外交易所以信用卡購買', '與其他人交易或交換', '挖礦取得', '其他'];

  public form = new FormData();

  public sms_otp_code: string = '';

  // public image1;
  // public image2;

  public smsOtpRules() {
    return [(v) => !!v || '請填寫',
            (v) => /^\d{8}$/.test(v) || '請填寫 8 碼的手機驗證碼']
  }

  public moreThanOneRules() {
    return [v => v.length>0 || '請勾選至少一項']
  }

  public notEmptyRules() {
    return [v => !!v || '請填寫']
  }

  public checkboxRules() {
    return [v => !!v || '請同意並勾選']
  }
  
  public fileChange(e) {
    this.form.append('file', e.target.files) //放進上傳的檔案
    // this.image1 = e.target.files[0];
  }

  public created() {
    const userProfile = readUserProfile(this.$store);
    // if (userProfile) {
    //   this.fullName = userProfile.full_name;
    // }

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  // public reset() {
  //   const userProfile = readUserProfile(this.$store);
  //   if (userProfile) {
  //     this.fullName = userProfile.full_name;
  //     this.birthday = '1990/1/1';
  //     this.phone_number = '';
  //     this.physical_address = '';
  //     this.ID_number = '';
  //     this.U_source =  [];
  //     this.checkbox1 = false;
  //     this.checkbox2 = false;
  //   }
  // }

  // public cancel() {
  //   this.$router.back();
  // }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
      if (this.sms_otp_code) {
        await dispatchSendKYC(this.$store, {"sms_code": this.sms_otp_code});
      }
    }
  }

  public addressRules() {
    return [(v) => (v.startsWith('T') || v.startsWith('0x')) || '地址應以 T 或 0x 開頭',]
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
